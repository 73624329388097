<template>
  <div class="chart-filter-block">
    <div class="tracker-datepicker">
      <!--suppress HtmlDeprecatedAttribute -->
      <el-date-picker
        :value="dateForChart"
        type="daterange"
        align="center"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd H:mm:ss"
        class="start-date-End"
        @input="changeDateForChart"
      />
      <span>
        {{ dateTitle }}
      </span>
    </div>
    <div class="timeInterval">
      <span>
        {{ dateStart }}
      </span>
      <span
        v-if="dateStart !== dateEnd"
      >
        →
        {{ dateEnd }}
      </span>
      <div
        v-if="duration > 1"
        class="how-days"
      >
        {{ duration }} days
      </div>
    </div>
    <div class="projects-tittle">
      <span>
        Projects:
      </span>
      <el-select
        :value="selectedProjects"
        multiple
        collapse-tags
        placeholder="Projects"
        value-key="uuid"
        class="projects-picker"
        @change="changeProjectForChart"
      >
        <el-option
          v-for="project in projects.all()"
          :key="project.uuid"
          :label="project.name"
          :value="project"
        />
      </el-select>
    </div>
    <div>
      <el-tooltip
        content="Refresh Dashboard Data"
        placement="top"
      >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          circle
          @click="refreshDashboard"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ChartFilter',
  data: () => ({
    isOpened: null,
    pickerOptions: {
      firstDayOfWeek: 1,
      shortcuts: [{
        text: 'Today',
        onClick(picker) {
          const end = moment().endOf('day').format('YYYY-MM-DD H:mm:ss');
          const start = moment().startOf('day').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'Yesterday',
        onClick(picker) {
          const end = moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD H:mm:ss');
          const start = moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'This week',
        onClick(picker) {
          const end = moment().isoWeekday('Sunday').endOf('day').format('YYYY-MM-DD H:mm:ss');
          const start = moment().isoWeekday('Monday').startOf('day').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'Last week',
        onClick(picker) {
          const end = moment().subtract(1, 'w').isoWeekday('Sunday').format('YYYY-MM-DD 23:59:59');
          const start = moment().subtract(1, 'w').isoWeekday('Monday').format('YYYY-MM-DD 00:00:00');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'This month',
        onClick(picker) {
          const end = moment().endOf('month').format('YYYY-MM-DD H:mm:ss');
          const start = moment().startOf('month').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'Last month',
        onClick(picker) {
          const end = moment().subtract(1, 'M').endOf('month').format('YYYY-MM-DD H:mm:ss');
          const start = moment().subtract(1, 'M').startOf('month').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'This year',
        onClick(picker) {
          const end = moment().endOf('year').format('YYYY-MM-DD H:mm:ss');
          const start = moment().startOf('year').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'Last year',
        onClick(picker) {
          const end = moment().subtract(1, 'y').endOf('year').format('YYYY-MM-DD H:mm:ss');
          const start = moment().subtract(1, 'y').startOf('year').format('YYYY-MM-DD H:mm:ss');

          picker.$emit('pick', [start, end]);
        },
      }],
    },
  }),

  computed: {
    ...mapGetters([
      'projectRepository',
      'user',
      'selectedProjectsForChart',
      'dateForChart',
      'tableChartData',
      'filteredProjectsForCharts',
      'organization',
    ]),
    selectedProjects() {
      if (!this.selectedProjectsForChart.length) {
        this.setProjectForChart(this.projects.all());
      }

      return this.selectedProjectsForChart;
    },
    projects() {
      return this.projectRepository.dataset;
    },
    duration() {
      const duration = moment.duration(moment(this.dateForChart[1])
        .diff(moment(this.dateForChart[0], 'YYYY-MM-DD H:mm:ss')));

      return Math.round(moment.duration(duration, 'milliseconds').asDays());
    },
    dateStart() {
      return moment(this.dateForChart[0], 'YYYY-MM-DD H:mm:ss').format('D MMMM');
    },
    dateEnd() {
      return moment(this.dateForChart[1], 'YYYY-MM-DD H:mm:ss').format('D MMMM');
    },
    dateTitle() {
      if (this.dateForChart[0] === moment().startOf('day').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().endOf('day').format('YYYY-MM-DD H:mm:ss')) {
        return 'Today';
      }
      if (this.dateForChart[0] === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD H:mm:ss')) {
        return 'Yesterday';
      }
      if (this.dateForChart[0] === moment().isoWeekday('Monday').startOf('day').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().isoWeekday('Sunday').endOf('day').format('YYYY-MM-DD H:mm:ss')) {
        return 'This week';
      }
      if (this.dateForChart[0] === moment().subtract(1, 'w').isoWeekday('Monday').format('YYYY-MM-DD 00:00:00')
        && this.dateForChart[1] === moment().subtract(1, 'w').isoWeekday('Sunday').format('YYYY-MM-DD 23:59:59')) {
        return 'Last week';
      }
      if (this.dateForChart[0] === moment().startOf('month').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().endOf('month').format('YYYY-MM-DD H:mm:ss')) {
        return 'This month';
      }
      if (this.dateForChart[0] === moment().subtract(1, 'M').startOf('month').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().subtract(1, 'M').endOf('month').format('YYYY-MM-DD H:mm:ss')) {
        return 'Last month';
      }
      if (this.dateForChart[0] === moment().startOf('year').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().endOf('year').format('YYYY-MM-DD H:mm:ss')) {
        return 'This year';
      }
      if (this.dateForChart[0] === moment().subtract(1, 'y').startOf('year').format('YYYY-MM-DD H:mm:ss')
        && this.dateForChart[1] === moment().subtract(1, 'y').endOf('year').format('YYYY-MM-DD H:mm:ss')) {
        return 'Last year';
      }

      return '';
    },
  },

  methods: {
    ...mapActions([
      'setProjectForChart',
      'setDateForChart',
      'clearProjects',
      'getProjects',
    ]),
    changeProjectForChart(selectedProjectsForChart) {
      this.setProjectForChart(selectedProjectsForChart);
    },
    changeDateForChart(dateForChart) {
      this.setDateForChart(dateForChart);
    },
    refreshDashboard() {
      this.clearProjects();
      this.getProjects({
        withTasks: true,
        organizationUuid: this.organization.uuid,
      });
      this.setProjectForChart(this.filteredProjectsForCharts);
    },
  },
};
</script>

<style scoped>
  .chart-filter-block {
    display         : grid;
    grid-template   : auto / auto auto auto auto;
    grid-gap        : 50px;
    justify-content : space-between;
    align-items     : center;
    color           : #6A717C;
  }

  .tracker-datepicker {
    font-size     : 28px;
    align-content : center;
  }

  .start-date-End {
    width : 280px;
  }

  .projects-tittle {
    font-size    : 18px;
    margin-right : 5px;
  }

  .projects-picker {
    width : 250px;
  }

  .timeInterval {
    font-size : 20px;
  }

  .how-days {
    font-size  : 16px;
    text-align : center;
  }
</style>
